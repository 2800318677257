import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e2ae9bfc = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _629980e7 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _7b2ec07a = () => interopDefault(import('../pages/afrekenen.vue' /* webpackChunkName: "pages/afrekenen" */))
const _a8ca09b8 = () => interopDefault(import('../pages/betaling/index.vue' /* webpackChunkName: "pages/betaling/index" */))
const _565afbaa = () => interopDefault(import('../pages/brochure-aanvragen.vue' /* webpackChunkName: "pages/brochure-aanvragen" */))
const _06823538 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _4993c8ec = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _3b9c577e = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _022ac0fc = () => interopDefault(import('../pages/nieuws.vue' /* webpackChunkName: "pages/nieuws" */))
const _d22406f6 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _25ab7993 = () => interopDefault(import('../pages/nieuws/_category/index.vue' /* webpackChunkName: "pages/nieuws/_category/index" */))
const _543a2e14 = () => interopDefault(import('../pages/nieuws/_category/_item/index.vue' /* webpackChunkName: "pages/nieuws/_category/_item/index" */))
const _1524f2cf = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _266740ca = () => interopDefault(import('../pages/over/_slug.vue' /* webpackChunkName: "pages/over/_slug" */))
const _5b7e36f6 = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _c661cca0 = () => interopDefault(import('../pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _4e7c5ab2 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _bb48081c = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _3df2a01e = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _70dc8498 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _316267fc = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _082cc4a0 = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _2071931e = () => interopDefault(import('../pages/speciaal-voor-scholen.vue' /* webpackChunkName: "pages/speciaal-voor-scholen" */))
const _e4f0e938 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _46b3bd3d = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _20cbbe36 = () => interopDefault(import('../pages/winkelwagen.vue' /* webpackChunkName: "pages/winkelwagen" */))
const _02e9dccf = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _415326fc = () => interopDefault(import('../pages/admin/abonnementen/index.vue' /* webpackChunkName: "pages/admin/abonnementen/index" */))
const _04c5c730 = () => interopDefault(import('../pages/admin/facturen/index.vue' /* webpackChunkName: "pages/admin/facturen/index" */))
const _01f69e04 = () => interopDefault(import('../pages/admin/offertes/index.vue' /* webpackChunkName: "pages/admin/offertes/index" */))
const _561394b2 = () => interopDefault(import('../pages/admin/pakketten.vue' /* webpackChunkName: "pages/admin/pakketten" */))
const _651a3c49 = () => interopDefault(import('../pages/admin/relaties/index.vue' /* webpackChunkName: "pages/admin/relaties/index" */))
const _5e0cf0d8 = () => interopDefault(import('../pages/betaling/afgerond.vue' /* webpackChunkName: "pages/betaling/afgerond" */))
const _30bdce56 = () => interopDefault(import('../pages/demo/rekenblobs.vue' /* webpackChunkName: "pages/demo/rekenblobs" */))
const _1886422d = () => interopDefault(import('../pages/demo/taalblobs.vue' /* webpackChunkName: "pages/demo/taalblobs" */))
const _2b1dd912 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _48bb3f04 = () => interopDefault(import('../pages/info/privacy-statement.vue' /* webpackChunkName: "pages/info/privacy-statement" */))
const _edcfaa28 = () => interopDefault(import('../pages/school/leerling.vue' /* webpackChunkName: "pages/school/leerling" */))
const _28b9b078 = () => interopDefault(import('../pages/admin/facturen/aanmaken.vue' /* webpackChunkName: "pages/admin/facturen/aanmaken" */))
const _021af070 = () => interopDefault(import('../pages/admin/offertes/aanmaken.vue' /* webpackChunkName: "pages/admin/offertes/aanmaken" */))
const _1fec1fea = () => interopDefault(import('../pages/admin/relaties/aanmaken.vue' /* webpackChunkName: "pages/admin/relaties/aanmaken" */))
const _4436b864 = () => interopDefault(import('../pages/admin/abonnementen/_id.vue' /* webpackChunkName: "pages/admin/abonnementen/_id" */))
const _57dc516c = () => interopDefault(import('../pages/admin/offertes/_id.vue' /* webpackChunkName: "pages/admin/offertes/_id" */))
const _32c7dc4f = () => interopDefault(import('../pages/admin/organisaties/_id.vue' /* webpackChunkName: "pages/admin/organisaties/_id" */))
const _c8311dca = () => interopDefault(import('../pages/admin/particulieren/_id.vue' /* webpackChunkName: "pages/admin/particulieren/_id" */))
const _260eeb44 = () => interopDefault(import('../pages/admin/scholen/_id.vue' /* webpackChunkName: "pages/admin/scholen/_id" */))
const _a441e438 = () => interopDefault(import('../pages/admin/studenten/_id.vue' /* webpackChunkName: "pages/admin/studenten/_id" */))
const _26e3811f = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _04cb93f0 = () => interopDefault(import('../pages/contact/_slug.vue' /* webpackChunkName: "pages/contact/_slug" */))
const _39e5b636 = () => interopDefault(import('../pages/kennisbank/_slug.vue' /* webpackChunkName: "pages/kennisbank/_slug" */))
const _3f4e914e = () => interopDefault(import('../pages/kennisbank/_slug/_item.vue' /* webpackChunkName: "pages/kennisbank/_slug/_item" */))
const _c9cf0f30 = () => interopDefault(import('../pages/producten/_slug.vue' /* webpackChunkName: "pages/producten/_slug" */))
const _ed29c8d6 = () => interopDefault(import('../pages/verleng/_id.vue' /* webpackChunkName: "pages/verleng/_id" */))
const _dadfb742 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _e09b7d32 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3104b33f = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _e2ae9bfc,
    name: "account"
  }, {
    path: "/admin",
    component: _629980e7,
    name: "admin"
  }, {
    path: "/afrekenen",
    component: _7b2ec07a,
    name: "afrekenen"
  }, {
    path: "/betaling",
    component: _a8ca09b8,
    name: "betaling"
  }, {
    path: "/brochure-aanvragen",
    component: _565afbaa,
    name: "brochure-aanvragen"
  }, {
    path: "/contact",
    component: _06823538,
    name: "contact"
  }, {
    path: "/inloggen",
    component: _4993c8ec,
    name: "inloggen"
  }, {
    path: "/kennisbank",
    component: _3b9c577e,
    name: "kennisbank"
  }, {
    path: "/nieuws",
    component: _022ac0fc,
    children: [{
      path: "",
      component: _d22406f6,
      name: "nieuws"
    }, {
      path: ":category",
      component: _25ab7993,
      name: "nieuws-category"
    }, {
      path: ":category/:item",
      component: _543a2e14,
      name: "nieuws-category-item"
    }]
  }, {
    path: "/over",
    component: _1524f2cf,
    name: "over",
    children: [{
      path: ":slug?",
      component: _266740ca,
      name: "over-slug"
    }]
  }, {
    path: "/over-ons",
    component: _5b7e36f6,
    name: "over-ons"
  }, {
    path: "/producten",
    component: _c661cca0,
    name: "producten"
  }, {
    path: "/registreren",
    component: _4e7c5ab2,
    name: "registreren"
  }, {
    path: "/school",
    component: _bb48081c,
    name: "school"
  }, {
    path: "/shop",
    component: _3df2a01e,
    children: [{
      path: "",
      component: _70dc8498,
      name: "shop"
    }, {
      path: ":category",
      component: _316267fc,
      name: "shop-category"
    }, {
      path: ":category/:product",
      component: _082cc4a0,
      name: "shop-category-product"
    }]
  }, {
    path: "/speciaal-voor-scholen",
    component: _2071931e,
    name: "speciaal-voor-scholen"
  }, {
    path: "/sso",
    component: _e4f0e938,
    name: "sso"
  }, {
    path: "/wachtwoord-vergeten",
    component: _46b3bd3d,
    name: "wachtwoord-vergeten"
  }, {
    path: "/winkelwagen",
    component: _20cbbe36,
    name: "winkelwagen"
  }, {
    path: "/zoekresultaten",
    component: _02e9dccf,
    name: "zoekresultaten"
  }, {
    path: "/admin/abonnementen",
    component: _415326fc,
    name: "admin-abonnementen"
  }, {
    path: "/admin/facturen",
    component: _04c5c730,
    name: "admin-facturen"
  }, {
    path: "/admin/offertes",
    component: _01f69e04,
    name: "admin-offertes"
  }, {
    path: "/admin/pakketten",
    component: _561394b2,
    name: "admin-pakketten"
  }, {
    path: "/admin/relaties",
    component: _651a3c49,
    name: "admin-relaties"
  }, {
    path: "/betaling/afgerond",
    component: _5e0cf0d8,
    name: "betaling-afgerond"
  }, {
    path: "/demo/rekenblobs",
    component: _30bdce56,
    name: "demo-rekenblobs"
  }, {
    path: "/demo/taalblobs",
    component: _1886422d,
    name: "demo-taalblobs"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _2b1dd912,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/privacy-statement",
    component: _48bb3f04,
    name: "info-privacy-statement"
  }, {
    path: "/school/leerling",
    component: _edcfaa28,
    name: "school-leerling"
  }, {
    path: "/admin/facturen/aanmaken",
    component: _28b9b078,
    name: "admin-facturen-aanmaken"
  }, {
    path: "/admin/offertes/aanmaken",
    component: _021af070,
    name: "admin-offertes-aanmaken"
  }, {
    path: "/admin/relaties/aanmaken",
    component: _1fec1fea,
    name: "admin-relaties-aanmaken"
  }, {
    path: "/admin/abonnementen/:id",
    component: _4436b864,
    name: "admin-abonnementen-id"
  }, {
    path: "/admin/offertes/:id",
    component: _57dc516c,
    name: "admin-offertes-id"
  }, {
    path: "/admin/organisaties/:id?",
    component: _32c7dc4f,
    name: "admin-organisaties-id"
  }, {
    path: "/admin/particulieren/:id?",
    component: _c8311dca,
    name: "admin-particulieren-id"
  }, {
    path: "/admin/scholen/:id?",
    component: _260eeb44,
    name: "admin-scholen-id"
  }, {
    path: "/admin/studenten/:id?",
    component: _a441e438,
    name: "admin-studenten-id"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _26e3811f,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/contact/:slug",
    component: _04cb93f0,
    name: "contact-slug"
  }, {
    path: "/kennisbank/:slug",
    component: _39e5b636,
    name: "kennisbank-slug",
    children: [{
      path: ":item?",
      component: _3f4e914e,
      name: "kennisbank-slug-item"
    }]
  }, {
    path: "/producten/:slug",
    component: _c9cf0f30,
    name: "producten-slug"
  }, {
    path: "/verleng/:id?",
    component: _ed29c8d6,
    name: "verleng-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _dadfb742,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _e09b7d32,
    name: "index"
  }, {
    path: "/*",
    component: _3104b33f,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
